<template>
  <div>
    <div class="a-modal overflow-auto" :class="`a-modal-${animation}`">
      <transition :name="`a-modal-${animation}`">
        <div v-show="visible" :class="dialogClass">
          <div class="a-modal__body">
            <slot />
          </div>
        </div>
      </transition>
    </div>
    <transition :name="`a-modal__backdrop-${animation}`">
      <div
        @click="$emit('update:show', false)"
        v-show="visible"
        class="a-modal__backdrop"
        :class="`a-modal__backdrop-${animation}`"
      ></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AModal",
  props: {
    show: Boolean,
    animation: {
      type: String,
      default: "fade"
    },
    size: {
      type: String,
      validator: val => ["", "sm", "lg", "xl"].includes(val),
      default: "md"
    }
  },
  data() {
    return {
      visible: this.show
    };
  },
  watch: {
    show() {
      this.toggle();
    }
  },
  computed: {
    dialogClass() {
      return [
        "a-modal__dialog",
        `a-modal__dialog-${this.animation}`,
        {
          [`a-modal-${this.size}`]: this.size
        }
      ];
    }
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
      this.$emit("update:show", this.visible);
    }
  }
};
</script>
